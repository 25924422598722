<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('storagePricing.tablePageTitle') | title" />
    <huboo-table
      id="custom-storage-pricing"
      ref="storage-pricing-table"
      hide-date-picker
      hide-search
      :headers="headers"
      :items="items"
      v-bind="options"
      :server-items-length="serverItemsLength"
      :loading="loading"
      @row-clicked="onRowSelected"
      @update:options="handleUpdateOptions"
      @remove="openConfirmationModal"
      clickable
      localPagination
      :remove="isAdmin"
      @confirm-delete="onRemove"
    >
      <template #actions>
        <v-btn
          :disabled="loading"
          color="primary"
          @click="openCreateModal"
          id="additionalPricingCreateButton"
        >
          {{ $t('storagePricing.createModal.createButton') }}
        </v-btn>
      </template>
    </huboo-table>

    <storage-pricing-edit-modal
      ref="edit-modal"
      v-if="selected"
      v-model="editModal"
      :selected="selected"
      :operating-regions="operatingRegions"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />
    <storage-pricing-create-modal
      v-model="createModal"
      :operating-regions="operatingRegions"
      @close="closeCreateModal"
      @updated-item="onUpdatedItem"
    />
  </huboo-page>
</template>

<script>
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import ModalActionsMixin from '@/mixins/modalActions'
import CreateModal from './CustomStoragePricingCreateModal.vue'
import EditModal from './CustomStoragePricingEditModal.vue'

export default {
  name: 'CustomStoragePricing',
  mixins: [ClientMixin, ModalActionsMixin],
  components: {
    'storage-pricing-create-modal': CreateModal,
    'storage-pricing-edit-modal': EditModal,
  },
  data() {
    return {
      editModal: false,
      editRequiresAdmin: false,
      createModal: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      selected: null,
    }
  },

  computed: {
    headers() {
      return [
        {
          text: title(this.$t('storagePricing.fields.priceListCode')),
          sortable: false,
          value: 'priceListCode',
        },
        {
          text: title(this.$t('storagePricing.fields.description')),
          sortable: false,
          value: 'description',
        },
        {
          text: title(this.$t('storagePricing.fields.originRegion')),
          sortable: false,
          value: 'originRegion',
        },
        {
          text: title(this.$t('storagePricing.fields.price')),
          sortable: false,
          value: 'price',
        },
        {
          text: title(this.$t('storagePricing.fields.updatedAt')),
          sortable: true,
          value: 'updatedAt',
        },
      ]
    },

    items() {
      return this.storagePricingList.map(item => {
        return {
          ...item,
          price: this.formatPrice(item.price, 3),
          createdAt: this.formatDate(item.createdAt),
          updatedAt: this.formatDate(item.updatedAt),
        }
      })
    },

    meta() {
      return this.storagePricingList.meta || { total: this.items.length }
    },

    operatingRegions() {
      return this.$store.getters['billing/getOriginRegions']?.map(data => ({
        text: data.code,
        value: data.code,
      }))
    },

    storagePricingList() {
      const list = this.$store.getters['customStoragePricing/getAll']
      if (Array.isArray(list))
        return list.map(l => ({
          ...l,
          loading: this.isDeleteLoading(l.id),
        }))
      else return []
    },

    url() {
      return '/storage-price-list/'
    },
  },

  methods: {
    fetch() {
      if (!this.loading) {
        const { options, box } = this
        const { page } = options
        const params = {}
        if (box) params.hubooBox = box.hubooBox
        if (page) params.page = page
        this.$store.dispatch('customStoragePricing/fetchCollection', {
          params,
        })
      }
    },

    isDeleteLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.url + id,
        method: 'DELETE',
      })
    },

    onRemove() {
      this.$store.dispatch('customStoragePricing/delete', this.selected.id)
    },
  },

  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
