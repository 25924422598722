<template>
  <modal-form
    :value="value"
    id="create-storage-pricing-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    :active-field="activeField"
  >
    <template #header>
      {{ $t('storagePricing.createModal.title') }}
    </template>

    <template #fields="{ errorMessages }">
      <div class="fields-container">
        <hub-text-field
          @keyup="onInputFocus('priceListCode')"
          @blur="onInputBlur"
          :error-messages="(errorMessages && errorMessages.priceListCode) || []"
          class="modal-form__field"
          name="price-list-code"
          v-model="formData.priceListCode"
          :label="$t('storagePricing.fields.priceListCode')"
        />
        <hub-text-field
          @keyup="onInputFocus('description')"
          @blur="onInputBlur"
          :error-messages="(errorMessages && errorMessages.description) || []"
          class="modal-form__field"
          name="description"
          v-model="formData.description"
          :label="$t('storagePricing.fields.description')"
        />
        <div class="break" />
        <RegionDropdown
          ref="regionDropdown"
          :items="operatingRegions"
          @region="updateRegion($event)"
          :error-messages="errorMessages"
          :current-region="formData.originRegion"
        />
        <hub-text-field
          @keyup="onInputFocus('price')"
          @blur="onInputBlur"
          :error-messages="(errorMessages && errorMessages.price) || []"
          name="price"
          v-model="formData.price"
          :label="$t('storagePricing.fields.price')"
          persistent-hint
        />
      </div>
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="closeAndClear" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import RegionDropdown from '@/components/forms/RegionDropdown.vue'

export default {
  name: 'CustomStoragePricingCreateModal',
  components: { RegionDropdown },
  props: {
    value: Boolean,
    operatingRegions: Array,
  },

  data() {
    return {
      formData: {
        priceListCode: '',
        description: '',
        originRegion: '',
        price: '',
      },
      activeField: '',
    }
  },

  computed: {
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: '/storage-price-list/',
      })
    },

    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },
    fields() {
      return Object.keys(this.formData)
    },

    box() {
      return this.$store.getters['billing/getSelectedBox'].hubooBox || 0
    },
  },

  watch: {
    selected: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.formData = this.convertedObject(newVal)
      }
    },
  },

  methods: {
    closeAndClear() {
      this.$emit('close')
      this.formData = {}
      this.$refs.regionDropdown.clearData()
    },
    onInputBlur() {
      this.activeField = ''
    },
    onInputFocus(inputName) {
      this.activeField = inputName
    },
    async onSubmit() {
      const res = await this.$store.dispatch('customStoragePricing/create', {
        data: { ...this.formData, huboo_box: this.box },
      })

      if (res?.status !== 201) return

      this.$emit('close')
      this.$emit('updated-item')
      this.formData = {}
    },

    updateRegion(e) {
      this.formData.originRegion = e
    },
  },
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.fields-container > * {
  flex-grow: 2;
}
.break {
  flex-basis: 100%;
  height: 0;
}
</style>
